<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script lang="ts">

export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
  background: #ebebeb;
  color: #4b4b4b;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4b4b4b;

}
a {
  color: #4b4b4b;
  text-decoration: none;
}
</style>
