import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from './firebase'
import VCalendar from "v-calendar"
import PrimeVue from 'primevue/config'
import InputText from "primevue/inputtext";
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import SplitButton from 'primevue/splitbutton';
import Divider from 'primevue/divider';
import Panel from 'primevue/panel';
import Breadcrumb from 'primevue/breadcrumb';
import TabMenu from 'primevue/tabmenu';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chips from 'primevue/chips';
import Chip from 'primevue/chip';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import Card from 'primevue/card';
import "primevue/resources/themes/saga-green/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';


firebase.init()
// firestore.init(firebaseApp)
const app = createApp(App)
app.use(store)
.use(router)
.use(VCalendar, {})
.use(PrimeVue)
.mount('#app')

app.component('InputText', InputText)
app.component('RadioButton', RadioButton)
app.component('Textarea', Textarea)
app.component('Button', Button)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Row', Row)
app.component('ColumnGroup', ColumnGroup)
app.component('SplitButton', SplitButton)
app.component('Divider', Divider)
app.component('Panel', Panel)
app.component('Breadcrumb', Breadcrumb)
app.component('TabMenu', TabMenu)
app.component('Checkbox', Checkbox)
app.component('SelectButton', SelectButton)
app.component('ToggleButton', ToggleButton)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Chips', Chips)
app.component('Chip', Chip)
app.component('Menubar', Menubar)
app.component('Menu', Menu)
app.component('Card', Card)

