
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEventEntry from "@/models/matchEventEntry";
import MatchEvent from "@/models/matchEvent";

export default defineComponent({
  async created() {
    const placeId =
      typeof this.$route.params.placeId == "string"
        ? this.$route.params.placeId
        : this.$route.params.placeId[0];
    const matchId =
        typeof this.$route.params.matchId == "string"
          ? this.$route.params.matchId
          : this.$route.params.matchId[0];
    if (this.$store.getters.hasAccess(this.$route.params.placeId)) {
      this.$store.commit("setCurrentPlaceById", { placeId: placeId });
      const fetchedMatch = await firebase.getMatchInfo(placeId, matchId)
      if (fetchedMatch != undefined) {
        this.match = fetchedMatch
      }
    } else {
      // リダイレクトするか、権限がない旨の表示
    }
  },
  data() {
    var tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    tommorow.setMinutes(0, 0, 0);
    return {
      match: {} as MatchEvent,
      name: "",
      email: "",
      phone: "",
      billiardClass: "",
      affiliation: "",
      description: "",
      buttonItems: [
        {
          label: "保存する",
          command: () => {
            this.publish();
          }
        }
      ],
      home: {icon: 'pi pi-home', to: '/dashboard/'},
      breadcrumbs: [
        {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
        {label: 'イベント詳細', to: "/dashboard/" + this.$route.params.placeId + "/matches/" + this.$route.params.matchId},
        {label: '参加者追加', to: this.$route.path},
      ],
    };
  },
  methods: {
    async checkForm() {
      const placeId =
        typeof this.$route.params.placeId == "string"
          ? this.$route.params.placeId
          : this.$route.params.placeId[0];
      const matchId =
        typeof this.$route.params.matchId == "string"
          ? this.$route.params.matchId
          : this.$route.params.matchId[0];
      
      const matchEventEntry = new MatchEventEntry({
        name: this.name,
        email: this.email,
        phone: this.phone,
        billiardClass: this.billiardClass,
        affiliation: this.affiliation,
        description: this.description,
        createdAt: new Date(),
        approvedAt: this.match.approveMethod == "auto" ? new Date() : null
      });
      await firebase.createMatchEventEntry(placeId, matchId, matchEventEntry)
      this.$router.replace("/dashboard/" + placeId + "/matches/" + matchId)
      return;
    }
  }
});
