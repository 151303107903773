<template>
    <div class="surface-0 p-2">
    <Breadcrumb :home="home" :model="breadcrumbs" />
        <h1>{{this.place.name}}</h1>

        <TabView v-model:activeIndex="activeIndex">
            <TabPanel header="基本情報">
                <ul class="list-none p-0 m-0">
            <li class="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap" v-for="[key, value] in Object.entries(this.place)" :key="key">
                <div class="text-500 w-6 md:w-2 font-medium">{{key}}</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-if="this.editingItemKey != key">{{value}}</div>
                <div v-if="this.editingItemKey == key" class="w-full md:w-8 md:flex-order-0 flex-order-1">
                    <Textarea
                        name="description"
                        v-model="this.editingItemValue"
                        :autoResize="true"
                        rows="5"
                        cols="40"
                        class="inputfield"
                        v-if="this.isDescription(key)"/>
                    <ToggleButton v-model="this.editingItemValue" onLabel="Public" offLabel="Private" onIcon="pi pi-check" offIcon="pi pi-times" v-else-if="this.isPublic(key)" />
                    <InputText type="text" v-model="this.editingItemValue" v-else/>
                </div>
                <div class="w-6 md:w-2 flex justify-content-end" v-if="this.isEditable(key)">
                    <Button label="編集する" icon="pi pi-pencil" class="p-button-text" @click="setEditingItem(key, value)" v-if="this.editingItemKey != key"></Button>
                    <div v-else>
                        <Button label="保存" class="m-1" @click="this.updateField(key)"/>
                        <Button label="キャンセル" class="p-button-outlined m-1" @click="unsetEditingItem(key)"/>
                    </div>
                </div>
            </li>
        </ul>
            </TabPanel>
            <TabPanel header="検索設定">
                <h3>現在の設定内容</h3>
                <p>店名、住所はデフォルトで検索語句として設定されます。</p>
                <div class="p-d-flex p-ai-center p-flex-column p-flex-sm-row">
                <Chip class="mr-2 mb-2 p-2" v-for="term in this.place.searchTerms" :key="term" removable @remove="removePlaceSearchTerm(term)">
                    {{term}}
                </Chip>
                </div>
                <h3>検索語句の追加</h3>
                <div class="inputfield mb-2" >
                <Chips v-model="searchTerms" />
                </div>
                <Button label="追加" @click="updatePlaceSearchTerms()"/>
                <h3>検索設定を更新</h3>
                <Button label="更新" @click="updateSearchTermSetting()"/>
            </TabPanel>
            <TabPanel header="Header III">
                <Button label="call geofire function" @click="callGeofireFunction()"/>
                <Button label="Update nearby place" @click="callUpdateNearbyPlace()"/>
            </TabPanel>
        </TabView>

        
        
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Place from "@/models/place";
import firebase from "@/firebase";
import { Unsubscribe } from "@firebase/util";

export default defineComponent({
    async created(){
        this.placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
        this.unsub = firebase.listenPlaceInfo(this.placeId, (place)=>{
            this.place = place
        })
    },
    unmounted(){
        this.unsub()
    },
    data(){
        return {
            placeId: "",
            place: {} as Place | undefined,
            home: {icon: 'pi pi-home', to: '/dashboard/'},
            breadcrumbs: [
                {label: '店舗詳細', to: "/dashboard/" + this.$route.params.placeId + "/profile"},
            ],
            editingItemKey: "" as string,
            editingItemValue: true as string | boolean,
            publicToggle:[
                {name: "公開する", value: true},
                {name: "非公開にする", value: false}
            ],
            searchTerms: [] as string[],
            activeIndex: 0,
            unsub: {} as Unsubscribe
        }
    },
    methods:{
        setEditingItem(key: string, value: string | boolean){
            if (this.isEditable(key)) {
                this.editingItemValue = value
                this.editingItemKey = key
            }
        },
        unsetEditingItem(key: string) {
            if (this.editingItemKey == key) {
                this.editingItemKey = ""
                this.editingItemValue = ""
            }
        },
        isEditable: function(key: string){
            return ["name", "description", "address", "public"].includes(key)
        },
        isDescription: function(key: string){
            return key == "description"
        },
        isPublic: function(key: string){
            return key == "public"
        },
        updateField: async function(){
            await firebase.updatePlaceField(this.placeId, {[this.editingItemKey] : this.editingItemValue})
            this.unsetEditingItem(this.editingItemKey)
        },
        updatePlaceSearchTerms: async function(){
            console.log(this.searchTerms)
            await firebase.updatePlaceSearchTerm(this.placeId, this.searchTerms)
            this.searchTerms = []
        },
        removePlaceSearchTerm: async function(term: string){
            const terms = [term]
            await firebase.removePlaceSearchTerm(this.placeId, terms)
        },
        updateSearchTermSetting: async function(){
            await firebase.callRefreshSearchTerm([this.placeId])
        },
        callGeofireFunction: async function(){
            await firebase.callAddGeofireField([this.placeId])
        },
        callUpdateNearbyPlace: async function(){
            await firebase.callUpdateNearbyPlace([this.placeId])
        }
    }
})
</script>

<style>

</style>