<template>
  <div>
  <div class="field col-8 surface-0 p-2">
  <Breadcrumb :home="home" :model="breadcrumbs" />
    <h1>新規イベントを作成する</h1>
    <form action="" @submit.prevent @submit="checkForm()">
    <h3>基本情報</h3>
      <div class="grid">
        <div class="col-12">
          <span class="p-float-label">
            <InputText name="title" type="text" v-model="title" class="inputfield w-full"/>
            <label for="title">タイトル</label>
          </span>
        </div>
      
        <div class="col-6">
          <label for="startDateTime">開始日時</label>
          <v-date-picker
            v-model="startDateTime"
            mode="dateTime"
            :minute-increment="5"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <InputText type="text" :value="inputValue" v-on="inputEvents" class="inputfield w-full"/>
            </template>
          </v-date-picker>
        </div>

        <div class="col-6">
          <label for="endDateTime">終了日時</label>
          <v-date-picker
            v-model="endDateTime"
            mode="dateTime"
            :minute-increment="5"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <InputText type="text" :value="inputValue" v-on="inputEvents" class="inputfield w-full"/>
            </template>
          </v-date-picker>
        </div>
      

      <div class="col-12">
        <div><label for="maxParticipants">試合形式</label></div>
      </div>
      <div class="col-3">
        <label for="matchType1"
          ><RadioButton
            id="matchType1"
            name="matchType"
            value="9 ball"
            v-model="matchType"
          />9 ball</label
        >
      </div>
      <div class="col-3">
        <label for="matchType2"
          ><RadioButton
            id="matchType2"
            name="matchType"
            value="8 ball"
            v-model="matchType"
          />8 ball</label
        >
      </div>
      <div class="col-3">
        <label for="matchType3"
          ><RadioButton
            id="matchType3"
            name="matchType"
            value="10 ball"
            v-model="matchType"
          />10 ball</label
        >
      </div>
      <!-- <div>
          <label for="class">参加資格</label>
          <input type="text" name="class">
          </div> -->
      <!-- <v-date-picker v-model="startDateTime" mode="dateTime"/> -->

      <!-- <div class="p-grid p-fluid"> -->
        <div class="col-6">
          <div><label for="title">開催場所</label></div>
          <InputText
            type="text"
            :value="this.$store.state.currentPlace.name"
            disabled
            class="inputfield w-full"
          />
        </div>
        <div class="col-6">
          <div>
            <label for="maxParticipants">最大参加人数</label>
            <div>
              <InputText
                name="maxParticipants"
                type="number"
                v-model.number="maxParticipants"
                class="inputfield w-full"
              />
            </div>
          </div>
        </div>

      <div class="col-12">
        <div class="w-full"><label for="entryStartDateTime">エントリー期間</label></div>
      </div>
      <div class="col-6">
        <label for="entryStartDateTime" class="text-500 text-sm">開始</label>
        <v-date-picker
            v-model="entryStartDateTime"
            mode="dateTime"
            :minute-increment="5"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <InputText type="text" :value="inputValue" v-on="inputEvents" class="inputfield w-full"/>
            </template>
          </v-date-picker>
      </div>
      <div class="col-6">
        <label for="entryStartDateTime" class="text-500 text-sm">終了</label>
          <v-date-picker
            v-model="entryEndDateTime"
            mode="dateTime"
            :minute-increment="5"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <InputText type="text" :value="inputValue" v-on="inputEvents" class="inputfield w-full"/>
            </template>
          </v-date-picker>
      </div>
      <div class="col-12">
        <div><label for="approveMethod">参加承諾</label></div>
        <div class="radiobutton p-2">
          <label for="approveMethod1"
            ><RadioButton
              id="approveMethod1"
              name="approveMethod1"
              value="auto"
              v-model="approveMethod"
            />エントリーがあった際に自動で参加を承諾する</label
          >
          </div>
        <div class="radiobutton p-2">
          <label for="approveMethod2"
            ><RadioButton
              id="approveMethod2"
              name="approveMethod2"
              value="manual"
              v-model="approveMethod"
            />手動で参加を承諾する</label
          >
          </div>
      </div>
          <div class="col-12">
        <div><label for="description">詳細</label></div>
        <Textarea
          name="description"
          v-model="description"
          :autoResize="true"
          rows="5"
          cols="30"
          class="inputfield w-full"
        />
          </div>

        <Divider />
        <div class="col-12">
        <h3>参加者に求める情報</h3>
        <p class="text-500 text-xs">参加者がエントリー時に入力が必要となります。</p>
        
        <div class="field-checkbox">
        <Checkbox name="name" value="name" v-model="selectedEntryInfo" />
        <label for="name">名前</label>
        </div>
        <div class="field-checkbox">
        <Checkbox name="class" value="class" v-model="selectedEntryInfo" />
        <label for="class">クラス</label>
        </div>
        <div class="field-checkbox">
          <Checkbox name="email" value="email" v-model="selectedEntryInfo" />
          <label for="email">Emailアドレス</label>
        </div>
        <div class="field-checkbox">
        <Checkbox name="phone" value="phone" v-model="selectedEntryInfo" />
        <label for="phone">電話番号</label>
        </div>
        
        <div class="field-checkbox">
        <Checkbox name="affiliation" value="affiliation" v-model="selectedEntryInfo" />
        <label for="affiliation">所属</label>
        </div>
        </div>

        <div class="col-6">
        <Button label="キャンセル" class="p-button-text inputfield w-full" @click="this.$router.back()" />
        </div>
        <div class="col-6">
  
        <SplitButton type="submit" label="下書きとして保存する" :model="buttonItems" class="inputfield w-full"></SplitButton>
        </div>
      </div>
    </form>
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEvent from "@/models/matchEvent";
import { MatchType } from "@/models/matchType";
import { MatchEventStatus } from "@/models/matchEventStatus";
import { MatchEventEntryInfo } from "@/models/matchEventEntryInfo";
import { MatchApproveMethod } from "@/models/matchApproveMethod";
import MatchEventEntry from "@/models/matchEventEntry";


export default defineComponent({
  async created() {
    const placeId =
      typeof this.$route.params.placeId == "string"
        ? this.$route.params.placeId
        : this.$route.params.placeId[0];
    if (this.$store.getters.hasAccess(this.$route.params.placeId)) {
      this.$store.commit("setCurrentPlaceById", { placeId: placeId });
    } else {
      // リダイレクトするか、権限がない旨の表示
    }
  },
  data() {
    var tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    tommorow.setMinutes(0, 0, 0);
    return {
      title: "",
      startDateTime: tommorow,
      endDateTime: tommorow,
      entryStartDateTime: tommorow,
      entryEndDateTime: tommorow,
      maxParticipants: 0,
      description: "",
      matchType: "9 ball" as MatchType,
      status: "draft" as MatchEventStatus,
      buttonItems: [
          {
              label: '公開する',
              command: () => {
                  this.publish()
              }
          }
      ],
      selectedEntryInfo: ["name", "billiardClass"] as MatchEventEntryInfo[],
      approveMethod: "auto" as MatchApproveMethod,
      home: {icon: 'pi pi-home', to: '/dashboard/'},
      breadcrumbs: [
                {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
                {label: 'イベント詳細', to: "/dashboard/" + this.$route.params.placeId + "/matches/" + this.$route.params.matchId},
            ],
    };
  },
  methods: {
    async checkForm() {
      const placeId =
        typeof this.$route.params.placeId == "string"
          ? this.$route.params.placeId
          : this.$route.params.placeId[0];
      const matchEvent = new MatchEvent(
        this.title,
        this.description,
        this.matchType,
        this.startDateTime,
        this.endDateTime,
        new Date(),
        "JP",
        this.maxParticipants,
        Array<MatchEventEntry>(),
        this.status,
        this.entryStartDateTime,
        this.entryEndDateTime,
        this.selectedEntryInfo,
        this.approveMethod,
        this.$store.state.currentPlace.name,
        this.$store.state.currentPlace.id,
      );
      await firebase.createMatchEvent(placeId, matchEvent);
      this.$router.replace("/dashboard/" + placeId + "/matches")
      return;
    },
    publish(){
        this.status = 'public'
        this.checkForm()
    }
  }
});
</script>

<style>

</style>