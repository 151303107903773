<template>
<div class="p-2">
  <h1>
    あなたがスタッフとなっている店舗
  </h1>
  <Button v-if="this.$store.state.claims.admin" @click="this.callAllAddGeofirestoreFieldToPlaceOnCall()">全てのPlaceにgフィールド追加</Button>
  <Button v-if="this.$store.state.claims.admin" @click="this.callAllUpdateNearbyPlace()">近接店舗を更新</Button>
  <Button v-if="this.$store.state.claims.admin" @click="this.callTestEmail()">email test</Button>
  <DataTable :value="this.$store.state.relatedPlaceArray">
    <Column field="name" header="店舗名" >
      <template #body="slotProps">
        <router-link :to="`/dashboard/${slotProps.data.id}/matches`">
          {{slotProps.data.name}}
        </router-link>
      </template>
    </Column>
    <Column field="id" header="ID"></Column>
    <Column field="public" header="公開ステータス">
      <template #body="slotProps">
          <div v-if="slotProps.data.public">
            公開
          </div>
          <div v-else>
            非公開
          </div>
      </template>
    </Column>
    <Column field="lastUpdatedAt" header="最終更新日時"></Column>
  </DataTable>
  </div>
</template>

<script lang="ts">
import firebase from "@/firebase";
import { defineComponent } from 'vue'

export default defineComponent({
    methods:{
      callAllAddGeofirestoreFieldToPlaceOnCall: async function(){
        await firebase.callAllAddGeofireField()
      },
      callAllUpdateNearbyPlace: async function(){
        await firebase.callAllUpdateNearbyPlace()
      },
      callTestEmail: async function(){
        await firebase.testEmail()
      }
    }
})
</script>

<style scoped>

</style>