<template>
  <header>
    <router-link to="/"><img src="@/assets/poolmatch_logo_1.png" alt="" id="logo"></router-link>
    <router-link to="/"><h1>poolmatch</h1></router-link>
    </header>
</template>


<style scoped>
    header{ height: 80px; display: flex; width: 100%; align-items: center;}
    h1 { margin-right: auto;}
    ul{ display: flex; list-style: none; margin-right: 10px;}
    li{padding: 10px 30px}
    #logo{ height: 80px;}
</style>