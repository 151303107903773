<template>
<div>
  <div class="field col-8 surface-0 p-3">
    <Breadcrumb :home="home" :model="breadcrumbs" />
    <h1>参加者を追加する</h1>
    <form action="" @submit.prevent @submit="checkForm()">
      <h3>基本情報</h3>
      <div class="grid">
        <div class="col-12">
          <span class="p-float-label">
            <InputText
              name="name"
              type="text"
              v-model="name"
              class="inputfield w-full"
            />
            <label for="name">名前</label>
          </span>
        </div>
        <div class="col-12">
          <span class="p-float-label">
            <InputText
              name="email"
              type="text"
              v-model="email"
              class="inputfield w-full"
            />
            <label for="email">Email</label>
          </span>
        </div>
        <div class="col-12">
          <span class="p-float-label">
            <InputText
              name="phone"
              type="text"
              v-model="phone"
              class="inputfield w-full"
            />
            <label for="phone">電話番号</label>
          </span>
        </div>
        <div class="col-12">
          <span class="p-float-label">
            <InputText
              name="billiardClass"
              type="text"
              v-model="billiardClass"
              class="inputfield w-full"
            />
            <label for="billiardClass">クラス</label>
          </span>
        </div>
        <div class="col-12">
          <span class="p-float-label">
            <InputText
              name="affiliation"
              type="text"
              v-model="affiliation"
              class="inputfield w-full"
            />
            <label for="affiliation">所属</label>
          </span>
        </div>

        <div class="col-12">
          <span class="p-float-label">
            <InputText
              name="description"
              type="text"
              v-model="description"
              class="inputfield w-full"
            />
            <label for="description">備考</label>
          </span>
        </div>


        <div class="col-6">
          <Button
            label="キャンセル"
            class="p-button-text inputfield w-full"
            @click="this.$router.back()"
          />
        </div>
        <div class="col-6">
          <Button
            type="submit"
            label="保存する"
            :model="buttonItems"
            class="inputfield w-full"
          ></Button>
        </div>
      </div>
    </form>
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEventEntry from "@/models/matchEventEntry";
import MatchEvent from "@/models/matchEvent";

export default defineComponent({
  async created() {
    const placeId =
      typeof this.$route.params.placeId == "string"
        ? this.$route.params.placeId
        : this.$route.params.placeId[0];
    const matchId =
        typeof this.$route.params.matchId == "string"
          ? this.$route.params.matchId
          : this.$route.params.matchId[0];
    if (this.$store.getters.hasAccess(this.$route.params.placeId)) {
      this.$store.commit("setCurrentPlaceById", { placeId: placeId });
      const fetchedMatch = await firebase.getMatchInfo(placeId, matchId)
      if (fetchedMatch != undefined) {
        this.match = fetchedMatch
      }
    } else {
      // リダイレクトするか、権限がない旨の表示
    }
  },
  data() {
    var tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    tommorow.setMinutes(0, 0, 0);
    return {
      match: {} as MatchEvent,
      name: "",
      email: "",
      phone: "",
      billiardClass: "",
      affiliation: "",
      description: "",
      buttonItems: [
        {
          label: "保存する",
          command: () => {
            this.publish();
          }
        }
      ],
      home: {icon: 'pi pi-home', to: '/dashboard/'},
      breadcrumbs: [
        {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
        {label: 'イベント詳細', to: "/dashboard/" + this.$route.params.placeId + "/matches/" + this.$route.params.matchId},
        {label: '参加者追加', to: this.$route.path},
      ],
    };
  },
  methods: {
    async checkForm() {
      const placeId =
        typeof this.$route.params.placeId == "string"
          ? this.$route.params.placeId
          : this.$route.params.placeId[0];
      const matchId =
        typeof this.$route.params.matchId == "string"
          ? this.$route.params.matchId
          : this.$route.params.matchId[0];
      
      const matchEventEntry = new MatchEventEntry({
        name: this.name,
        email: this.email,
        phone: this.phone,
        billiardClass: this.billiardClass,
        affiliation: this.affiliation,
        description: this.description,
        createdAt: new Date(),
        approvedAt: this.match.approveMethod == "auto" ? new Date() : null
      });
      await firebase.createMatchEventEntry(placeId, matchId, matchEventEntry)
      this.$router.replace("/dashboard/" + placeId + "/matches/" + matchId)
      return;
    }
  }
});
</script>

<style>
</style>