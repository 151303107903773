import { createStore } from 'vuex'
import { User } from "firebase/auth";
import Place from '@/models/place';

export default createStore({
  state: {
    user: {} as User,
    isSignedin: false,
    relatedPlaceArray: [] as Place[],
    currentPlace: {} as Place,
    claims: {}
  },
  mutations: {
    onAuthStateChanged(state, user) {
      state.user = user; //firebaseが返したユーザー情報
    },
    onUserStatusChanged(state, status) {
      state.isSignedin = status; //ログインしてるかどうか true or false
    },
    realtedPlaceArrayFetched(state, placeArray) {
      state.relatedPlaceArray = placeArray
    },
    setCurrentPlaceById(state, payload) {
      const currentPlace = state.relatedPlaceArray.filter((place) => place.id == payload.placeId)
      state.currentPlace = currentPlace[0]
    },
    setClaims(state, claims){
      state.claims = claims
    }
  },
  getters:{
    hasAccess: (state) => (placeId: string) => {
      return state.relatedPlaceArray.map((place)=> place.id).includes(placeId)
    }
  },
  actions: {
    user(context) {
      return context.state.user;
    },
    isSignedIn(context) {
      return context.state.isSignedin;
    },
    
  },
  modules: {
  }
})