export default class MatchEventEntry {
    id?: string
    name: string
    email: string
    phone: string
    affiliation: string
    billiardClass: string
    userId: string | null
    description: string | null
    createdAt: Date | null
    approvedAt: Date | null
    rejectedAt: Date | null
    canceledAt: Date | null
    constructor(init: Partial<MatchEventEntry>){
        this.id = init.id
        this.name = init.name ?? ''
        this.email = init.email ?? ''
        this.phone = init.phone ?? ''
        this.affiliation = init.affiliation ?? ''
        this.billiardClass = init.billiardClass ?? ''
        this.userId = init.userId ?? null
        this.description = init.description ?? null
        this.createdAt = init.createdAt ?? null
        this.approvedAt = init.approvedAt ?? null
        this.rejectedAt = init.rejectedAt ?? null
        this.canceledAt = init.canceledAt ?? null
    }

    get status():string {
        // キャンセルが最優先
        if (this.canceledAt != null) {
            return "canceled"
        }
        // キャンセルされていなかったとき、承認と拒否でTimestampが後のほうが優先
        if (this.approvedAt == null) {
            if (this.rejectedAt == null) {
                return "notApprovedYet"
            } else {
                return "rejected"
            }
        } else {
            if (this.rejectedAt == null) {
                return "approved"
            } else {
                if (this.rejectedAt < this.approvedAt) {
                    return "approved"
                } else {
                    return "rejected"
                }  
            }
        }
    }
}