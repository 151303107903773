import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  UserCredential,
  connectAuthEmulator,
  Unsubscribe,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  DocumentData,
  where,
  QueryDocumentSnapshot,
  SnapshotOptions,
  connectFirestoreEmulator,
  addDoc,
  Timestamp,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  arrayUnion,
  setDoc,
  arrayRemove,
  writeBatch,
} from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import MatchEvent from "./models/matchEvent";
import MatchEventEntry from "./models/matchEventEntry";
import { MatchEventStatus } from "./models/matchEventStatus";
import Place from "./models/place";
import store from "./store";

const config = {
  apiKey: "AIzaSyB7Xf-3kgdl8KvvPEZkhqArIAOAB2uzSBM",
  authDomain: "poolmatch-18fff.firebaseapp.com",
  projectId: "poolmatch-18fff",
  storageBucket: "poolmatch-18fff.appspot.com",
  messagingSenderId: "964448471318",
  appId: "1:964448471318:web:c4eb7c11cf8acb3ea6e837",
  measurementId: "G-HMGEVBB9V1",
};
const isEmulator = () => {
  const useEmulator = process.env.NEXT_PUBLIC_USE_FIREBASE_EMULATOR
  return !!(useEmulator && useEmulator === 'true')
}
const app = initializeApp(config);
const region = isEmulator() ? undefined : 'asia-northeast1'
const functions = getFunctions(app, region)

export default {
  init(): void {
    // const app = initializeApp(config);
    const auth = getAuth(app);
    auth.setPersistence(browserLocalPersistence);
    const db = getFirestore(app);
    // const region = isEmulator() ? undefined : 'asia-northeast1'
    // const functions = getFunctions(app, region)
    console.log(process.env.VUE_APP_ENV);
    if (process.env.VUE_APP_ENV == "dev") {
      console.log("dev env");
      connectAuthEmulator(auth, "http://localhost:9099");
      connectFirestoreEmulator(db, "localhost", 8080);
      connectFunctionsEmulator(functions, "localhost", 5001)
    }
    console.log(functions.region);
    return;
  },
  signin(email: string, password: string): Promise<UserCredential> {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  },
  signout(): Promise<void> {
    const auth = getAuth();
    return auth.signOut();
  },
  signup(email: string, password: string): Promise<UserCredential> {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password);
  },
  async getMatchEventList(placeId: string): Promise<Array<MatchEvent>> {
    const matchEventList: Array<MatchEvent> = [];
    const firestore = getFirestore();
    const q = query(
      collection(firestore, "places", placeId, "matchEvents").withConverter(
        matchEventConverter
      ),
      orderBy("startDateTime", "asc"),
      where("startDateTime", ">=", new Date()),
      limit(10)
    );
    const docList = await getDocs(q);
    docList.docs.forEach((doc) => {
      matchEventList.push(doc.data());
    });
    return matchEventList;
  },

  listenMatchEventList(placeId: string, matchEventList:Array<MatchEvent>,  onNext: (matchEventList: MatchEvent[]) => void): Unsubscribe {
    const firestore = getFirestore();
    const q = query(
      collection(firestore, "places", placeId, "matchEvents").withConverter(
        matchEventConverter
      ),
      orderBy("startDateTime", "desc"),
      // where("startDateTime", ">=", new Date()),
      limit(10)
    ).withConverter(matchEventConverter);
    return onSnapshot(q, (snapshot)=>{
      snapshot.docChanges().forEach((change)=>{
        switch (change.type) {
          case "added":
            matchEventList.push(change.doc.data())
            break;
          case "modified":
            matchEventList[change.oldIndex] = change.doc.data()
            break;
          case "removed":
            break;
          default:
            break;
        }
        onNext(matchEventList)
      })
    })
  },

  async getMatchInfo(
    placeId: string,
    matchId: string
  ): Promise<MatchEvent | undefined> {
    const firestore = getFirestore();
    const docRef = doc(
      firestore,
      "places",
      placeId,
      "matchEvents",
      matchId
    ).withConverter(matchEventConverter);
    try {
      const snapshot = await getDoc(docRef);
      return snapshot.data();
    } catch (error) {
      console.log(error);
      return;
    }
  },

  async getPlaceInfo(placeId: string): Promise<Place | undefined> {
    const firestore = getFirestore();
    const docRef = doc(firestore, "places", placeId).withConverter(
      placeConverter
    );
    try {
      const snapshot = await getDoc(docRef);
      return snapshot.data();
    } catch (error) {
      console.log(error);
      return;
    }
  },

  listenPlaceInfo(placeId: string, onNext: (place: Place | undefined)=>void): Unsubscribe {
    const firestore = getFirestore();
    const docRef = doc(firestore, "places", placeId).withConverter(
      placeConverter
    );
    return onSnapshot(docRef,(snapshot)=>{
      onNext(snapshot.data())
    })
  },

  /* eslint-disable @typescript-eslint/no-explicit-any*/
  async updatePlaceField(placeId: string, data: {[key: string]: any}): Promise<void>{
    const firestore = getFirestore();
    const docRef = doc(firestore, "places", placeId)
    data.lastUpdatedAt = new Date()
    return await updateDoc(docRef, data)
  },

  /* eslint-disable @typescript-eslint/no-explicit-any*/
  async updatePlaceSearchTerm(placeId: string, terms: string[]): Promise<void>{
    if (terms.length == 0) {
      return
    }
    const firestore = getFirestore();
    const docRef = doc(firestore, "places", placeId)
    const snapshot = await getDoc(docRef)
    const fetchedData = snapshot != undefined ? snapshot.data() : {}
    if (fetchedData != undefined) {
      if (fetchedData["searchTerms"] != undefined) {
        return await updateDoc(docRef, {searchTerms: arrayUnion(...terms)})        
      }
      return await setDoc(docRef, {searchTerms: terms}, {merge: true})
    }
  },
  /* eslint-disable @typescript-eslint/no-explicit-any*/
  async removePlaceSearchTerm(placeId: string, terms: string[]): Promise<void>{
    const firestore = getFirestore();
    const docRef = doc(firestore, "places", placeId)
    const snapshot = await getDoc(docRef)
    const fetchedData = snapshot != undefined ? snapshot.data() : {}
    if (fetchedData != undefined) {
      if (fetchedData["searchTerms"] != undefined) {
        return await updateDoc(docRef, {searchTerms: arrayRemove(...terms)})        
      }
      return
  }
    return
  },
  async callRefreshSearchTerm(placeIdArray: string[]): Promise<void> {
    console.log(functions.region)
    const refreshPlaceSearchToken = httpsCallable(functions, "refreshPlaceSearchTokenOnCall")
    await refreshPlaceSearchToken({placeIdArray: placeIdArray})
  },
  async callAddGeofireField(placeIdArray: string[]): Promise<void> {
    const addGeofirestoreFieldToPlaceOnCall = httpsCallable(functions, "addGeofirestoreFieldToPlaceOnCall")
    await addGeofirestoreFieldToPlaceOnCall({placeIdArray: placeIdArray})
  },
  async callAllAddGeofireField(): Promise<void> {
    const allAddGeofirestoreFieldToPlaceOnCall = httpsCallable(functions, "allAddGeofirestoreFieldToPlaceOnCall")
    await allAddGeofirestoreFieldToPlaceOnCall({})
  },
  async callUpdateNearbyPlace(placeIdArray: string[]): Promise<void> {
    const updateNearbyPlaceOnCall = httpsCallable(functions, "updateNearbyPlaceOnCall")
    await updateNearbyPlaceOnCall({placeIdArray: placeIdArray})
  },
  async callAllUpdateNearbyPlace(): Promise<void> {
    const allUpdateNearbyPlaceOnCall = httpsCallable(functions, "allUpdateNearbyPlaceOnCall")
    await allUpdateNearbyPlaceOnCall({})
  },
  async testEmail(): Promise<void> {
    const testSendEmail = httpsCallable(functions, "testSendEmail")
    await testSendEmail({})
  },

  async updateEntryStatus(placeId: string, matchId: string, entries: MatchEventEntry[], status: "approve" | "reject"): Promise<void>{
    const firestore = getFirestore();
    const batch = writeBatch(firestore)
    let data = {}
    if (status == "approve") {
      data = {
        approvedAt: new Date()
      }
    } else if (status == "reject") {
      data = {
        rejectedAt: new Date()
      }
    }
    entries.forEach((entry)=>{
      if (entry.id != undefined) {
        batch.update(
          doc(
            firestore,
            "places",
            placeId,
            "matchEvents",
            matchId,
            "entries",
            entry.id
          ),
          data
        ) 
      }
    })
    await batch.commit()
  },

  async updateMatchStatus(placeId: string, matches: MatchEvent[], matchEventstatus: MatchEventStatus): Promise<void>{
    const firestore = getFirestore();
    const batch = writeBatch(firestore)
    const data = {
      status: matchEventstatus
    }
    matches.forEach((match)=>{
      if (match.id != undefined) {
        batch.update(
          doc(
            firestore,
            "places",
            placeId,
            "matchEvents",
            match.id
          ),
          data
        ) 
      }
    })
    await batch.commit()
  },

  async getMatchEventEntries(
    placeId: string,
    matchId: string
  ): Promise<MatchEventEntry[]> {
    let matchEventList: Array<MatchEventEntry> = [];
    const firestore = getFirestore();
    const q = query(
      collection(
        firestore,
        "places",
        placeId,
        "matchEvents",
        matchId,
        "entries"
      ).withConverter(matchEventEntryConverter),
      limit(30)
    );
    const snapshot = await getDocs(q);
    matchEventList = snapshot.docs.map((doc) => {
      return doc.data();
    });
    return matchEventList;
  },

  async createMatchEventEntry(
    placeId: string,
    matchId: string,
    matchEventEntry: MatchEventEntry
  ): Promise<void> {
    const firestore = getFirestore();
    await addDoc(
      collection(
        firestore,
        "places",
        placeId,
        "matchEvents",
        matchId,
        "entries"
      ).withConverter(matchEventEntryConverter),
      matchEventEntry
    );
    return;
  },

  async createMatchEvent(
    placeId: string,
    matchEvent: MatchEvent
  ): Promise<void> {
    const firestore = getFirestore();
    await addDoc(
      collection(firestore, "places", placeId, "matchEvents").withConverter(
        matchEventConverter
      ),
      matchEvent
    );
    return;
  },

  async getRelatedPlaceArray(userId: string): Promise<void> {
    if (userId == undefined) {
      return;
    }
    const db = getFirestore();
    const q = query(
      collection(db, "places").withConverter(placeConverter),
      where(`staff.${userId}`, "==", true)
    );
    const docList = await getDocs(q);
    const placeArray = docList.docs.map((snapshot) => {
      return snapshot.data();
    });
    store.commit("realtedPlaceArrayFetched", placeArray);
  },

  async getPlaceArrayForSuperAdmin(): Promise<void> {
    const db = getFirestore();
    const q = query(
      collection(db, "places").withConverter(placeConverter),
      orderBy("lastUpdatedAt", "asc")
    );
    const docList = await getDocs(q);
    const placeArray = docList.docs.map((snapshot) => {
      return snapshot.data();
    });
    store.commit("realtedPlaceArrayFetched", placeArray);
  },
};


const placeConverter = {
  toFirestore(place: Place): DocumentData {
    return {
      name: place.name,
      description: place.description,
      countryCode: place.countryCode,
      createdAt: place.createdAt,
      lastUpdatedAt: place.lastUpdatedAt,
      address: place.address,
      public: place.public,
      staff: place.staff,
      searchTerms: place.searchTerms
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Place {
    const data = snapshot.data(options);
    const t2d = timestampToDate(data);
    return new Place({
      id: snapshot.id,
      name: t2d.name,
      description: t2d.description,
      countryCode: t2d.countryCode,
      createdAt: t2d.createdAt,
      lastUpdatedAt: t2d.lastUpdatedAt,
      address: t2d.address,
      public: t2d.public,
      staff: t2d.staff,
      searchTerms: t2d.searchTerms
    });
  },
};

const matchEventConverter = {
  toFirestore(matchEvent: MatchEvent): DocumentData {
    return {
      title: matchEvent.title,
      description: matchEvent.description,
      matchType: matchEvent.matchType,
      startDateTime: matchEvent.startDateTime,
      endDateTime: matchEvent.endDateTime,
      createdAt: matchEvent.createdAt,
      countryCode: matchEvent.countryCode,
      maxParticipants: matchEvent.maxParticipants,
      status: matchEvent.status,
      involvedUsers: matchEvent.involvedUsers,
      startEntryDateTime: matchEvent.startEntryDateTime,
      endEntryDateTime: matchEvent.endEntryDateTime,
      requiredInfo: matchEvent.requiredInfo,
      approveMethod: matchEvent.approveMethod,
      placeName: matchEvent.placeName,
      placeId: matchEvent.placeId
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): MatchEvent {
    const data = snapshot.data(options);
    const t2d = timestampToDate(data);
    return new MatchEvent(
      t2d.title,
      t2d.description,
      t2d.matchType,
      t2d.startDateTime,
      t2d.endDateTime,
      t2d.createdAt,
      t2d.countryCode,
      t2d.maxParticipants,
      t2d.involvedUsers,
      t2d.status,
      t2d.startEntryDateTime,
      t2d.endEntryDateTime,
      t2d.requiredInfo,
      t2d.approveMethod,
      t2d.placeName,
      t2d.placeId,
      snapshot.id
    );
  },
};

const matchEventEntryConverter = {
  toFirestore(matchEventEntry: MatchEventEntry): DocumentData {
    return {
      name: matchEventEntry.name,
      phone: matchEventEntry.phone,
      email: matchEventEntry.email,
      affiliation: matchEventEntry.affiliation,
      billiardClass: matchEventEntry.billiardClass,
      createdAt: matchEventEntry.createdAt,
      approvedAt: matchEventEntry.approvedAt,
      rejectedAt: matchEventEntry.rejectedAt,
      userId: matchEventEntry.userId,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): MatchEventEntry {
    const data = snapshot.data(options);
    const t2d = timestampToDate(data);
    return new MatchEventEntry({
      id: snapshot.id,
      name: t2d.name,
      phone: t2d.phone,
      email: t2d.email,
      affiliation: t2d.affiliation,
      billiardClass: t2d.billiardClass,
      createdAt: t2d.createdAt,
      approvedAt: t2d.approvedAt,
      rejectedAt: t2d.rejectedAt,
      userId: t2d.userId,
    });
  },
};

function timestampToDate(data: DocumentData) {
  /* eslint-disable @typescript-eslint/no-explicit-any*/
  const t2dEntries: [string, any][] = Object.entries(data).map(([key, val]) => {
    if (val instanceof Timestamp) {
      return [key, val.toDate()];
    } else if (val instanceof Array) {
      const newVal = val.map((v) =>
        v instanceof Timestamp
          ? v.toDate()
          : v instanceof Object
          ? timestampToDate(v)
          : v
      );
      return [key, newVal];
    } else if (val instanceof Object) {
      return [key, timestampToDate(val)];
    } else {
      return [key, val];
    }
  });
  return Object.fromEntries(t2dEntries);
}
