<template>
  <footer>
      <nav>
      <ul>
        <li><router-link to="/what_is_new">What's new?</router-link></li>
        <li><router-link to="/privacy_policy">Privacy Policy</router-link></li>
        <li><router-link to="/terms_of_service">Terms of service</router-link></li>
      </ul>
    </nav>   
  </footer>
</template>

<script>
export default {

}
</script>

<style>
    footer {
        /* background-color: #000; */
        /* color: #fff; */
        text-align: center;
        padding: 0 20px;
        /* margin-top: 160px; */
    }
    ul > li {
        display: inline-block;
        margin-right: 2em;
    }
    ul a {
        color: #4b4b4b;
        text-decoration: none;
    }
    @media (min-width: 768px) {
        footer {
            padding: 0;
            height: 50px;
            display: grid;
            grid-template-columns: 1fr 700px 1fr;
            grid-template-rows: 1fr;
        }
        footer > * {
            grid-column: 2;
            grid-row: 1;
        }
        p {
            justify-self: start;
            align-self: center;
        }
        ul {
            justify-self: end;
            align-self: center;
        }
    }
    @media (min-width: 1024px) {
        footer {
            grid-template-columns: 1fr 980px 1fr;
        }
    }
</style>