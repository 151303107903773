import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import FirstView from "@/views/FirstView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import WhatIsNew from "@/views/WhatIsNew.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue"
import DashboardHome from "@/views/dashboard/Home.vue"
import Signin from "@/views/Signin.vue"
import Signup from "@/views/Signup.vue"
import MatchList from '@/views/dashboard/MatchList.vue'
import { getAuth, } from "firebase/auth";
import Base from "@/views/Base.vue";
import MatchAdd from '@/views/dashboard/MatchAdd.vue'
import MatchDetail from '@/views/dashboard/MatchDetail.vue'
import PlaceDetail from '@/views/dashboard/PlaceDetail.vue'
import ParticipantAdd from '@/views/dashboard/ParticipantAdd.vue'
import store from '@/store'
import firebase from '@/firebase';
import News from "@/views/News.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Base",
    component: Base,
    children: [
      { 
        path:"",
        component: Home,
        children:[
          {
            path: "",
            name: "FirstView",
            component: FirstView,
          },
          {
            path: "privacy_policy",
            name: "PrivacyPolicy",
            component: PrivacyPolicy,
          },
          {
            path: "terms_of_service",
            name: "TermsOfService",
            component: TermsOfService,
          },
          {
            path: "what_is_new",
            name: "WhatIsNew",
            component: WhatIsNew,
          },
          {
            path: "signin",
            name: "Signin",
            component: Signin,
          },
          {
            path: "signup",
            name: "Signup",
            component: Signup,
          },
          {
            path: "illustration",
            name: "illustration",
            component: News,
          },
        ]
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {requiresAuth: true},
        children: [
          {
            path: "",
            name: "DashboardHome",
            component: DashboardHome,
          },
          {
            path: ":placeId/matches",
            name: "matches",
            component: MatchList,
          },
          {
            path: ":placeId/profile",
            name: "profile",
            component: PlaceDetail,
          },
          {
            path: ":placeId/matches/:matchId",
            name: "matchDetail",
            component: MatchDetail,
          },
          {
            path: ":placeId/matches/add",
            name: "matchAdd",
            component: MatchAdd,
          },
          {
            path: ":placeId/matches/:matchId/addParticipants",
            name: "addParticipants",
            component: ParticipantAdd
          }
        ]
      },
    ]
  },
]
console.log(routes)

// ログインしたら、userIdと紐付いているplaceのHome画面に遷移する。
// 各画面で、userIDに紐づくplaceIdを返す処理を追加。
// storeで紐づくplaceIdの配列と、現在のplaceIdをstateで保持する。


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=>{
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const auth = getAuth()
    auth.onAuthStateChanged(async(user) => {
      if (user) {
        console.log("user is not null")
        console.log(user)
        store.commit('onAuthStateChanged', user)
        store.commit('onUserStatusChanged', user ? true : false)
        // adminかどうかを判断
        const tokenResult = await user.getIdTokenResult()
        // adminであれば全部のPlaceを取得してStoreに格納した上でnext()
        if (tokenResult.claims.admin) {
          await firebase.getPlaceArrayForSuperAdmin()
          store.commit('setClaims', tokenResult.claims)
          next()
        } else {
          // adminでなければ以下の処理
          if (to.params.placeId != undefined) {
            await firebase.getRelatedPlaceArray(user.uid)
            if (store.getters.hasAccess(to.params.placeId)) {
              next()
            } else {
              next({path: '/dashboard'})
            }
          }else{
            next()
          }
        }
      } else {
        next({path: '/signin'})
      }
    })
  } else {
    next()
  }
})

export default router
