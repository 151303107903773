
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEvent from "@/models/matchEvent";
import { MatchType } from "@/models/matchType";
import { MatchEventStatus } from "@/models/matchEventStatus";
import { MatchEventEntryInfo } from "@/models/matchEventEntryInfo";
import { MatchApproveMethod } from "@/models/matchApproveMethod";
import MatchEventEntry from "@/models/matchEventEntry";


export default defineComponent({
  async created() {
    const placeId =
      typeof this.$route.params.placeId == "string"
        ? this.$route.params.placeId
        : this.$route.params.placeId[0];
    if (this.$store.getters.hasAccess(this.$route.params.placeId)) {
      this.$store.commit("setCurrentPlaceById", { placeId: placeId });
    } else {
      // リダイレクトするか、権限がない旨の表示
    }
  },
  data() {
    var tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    tommorow.setMinutes(0, 0, 0);
    return {
      title: "",
      startDateTime: tommorow,
      endDateTime: tommorow,
      entryStartDateTime: tommorow,
      entryEndDateTime: tommorow,
      maxParticipants: 0,
      description: "",
      matchType: "9 ball" as MatchType,
      status: "draft" as MatchEventStatus,
      buttonItems: [
          {
              label: '公開する',
              command: () => {
                  this.publish()
              }
          }
      ],
      selectedEntryInfo: ["name", "billiardClass"] as MatchEventEntryInfo[],
      approveMethod: "auto" as MatchApproveMethod,
      home: {icon: 'pi pi-home', to: '/dashboard/'},
      breadcrumbs: [
                {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
                {label: 'イベント詳細', to: "/dashboard/" + this.$route.params.placeId + "/matches/" + this.$route.params.matchId},
            ],
    };
  },
  methods: {
    async checkForm() {
      const placeId =
        typeof this.$route.params.placeId == "string"
          ? this.$route.params.placeId
          : this.$route.params.placeId[0];
      const matchEvent = new MatchEvent(
        this.title,
        this.description,
        this.matchType,
        this.startDateTime,
        this.endDateTime,
        new Date(),
        "JP",
        this.maxParticipants,
        Array<MatchEventEntry>(),
        this.status,
        this.entryStartDateTime,
        this.entryEndDateTime,
        this.selectedEntryInfo,
        this.approveMethod,
        this.$store.state.currentPlace.name,
        this.$store.state.currentPlace.id,
      );
      await firebase.createMatchEvent(placeId, matchEvent);
      this.$router.replace("/dashboard/" + placeId + "/matches")
      return;
    },
    publish(){
        this.status = 'public'
        this.checkForm()
    }
  }
});
