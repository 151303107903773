<template>
  <div id="first-view">
    <h1>{{ msg }}</h1>
    <About></About>
  </div>
</template>

<script>
import About from '@/components/About'
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'FirstView',
  components: {About},
  data () {
    return {
      msg: 'more open and more connected for billiard players.'
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#first-view{
  text-align: center;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
