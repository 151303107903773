<template>
  <div class="surface-0 p-2">  
  <Breadcrumb :home="home" :model="breadcrumbs" />
      <h1>
          {{this.$store.state.currentPlace.name}}のイベント一覧
      </h1>
      <Button class="m-1" @click="$router.push(`/dashboard/${this.$route.params.placeId}/matches/add`)" label="新規作成する" v-if="selectedMatchEvents.length == 0"/>
      <div v-if="selectedMatchEvents.length != 0">
                <Button label="公開する" class="m-1" @click="this.updateMatchStatus('public')"/>
                <Button label="下書きに戻す" class="p-button-outlined m-1" @click="this.updateMatchStatus('draft')"/>
                <Button label="中止する" class="p-button-outlined p-button-danger m-1" @click="this.updateMatchStatus('canceled')"/>
      </div>
      <DataTable :value="this.matchEventList" v-model:selection="selectedMatchEvents" dataKey="id" :scrollable="true" scrollHeight="400px" scrollDirection="both">
        <Column selectionMode="multiple"></Column>
        <Column field="status" header="ステータス" :style="{width:'200px'}"></Column>
        <Column field="title" header="タイトル" :style="{width:'200px'}">
            <template #body="slotProps">
                <router-link :to="`/dashboard/${this.$route.params.placeId}/matches/${slotProps.data.id}` ">
                {{slotProps.data.title}}
                </router-link>
            </template>
        </Column>
        <Column field="id" header="ID" :style="{width:'200px'}"></Column>
        <Column field="startDateTime" header="開始日時" :style="{width:'200px'}"></Column>     
        <Column field="startEntryDateTime" header="エントリー開始" :style="{width:'200px'}"></Column>
        <Column field="matchType" header="試合形式" :style="{width:'200px'}"></Column>
        <Column field="maxParticipants" header="上限参加人数" :style="{width:'200px'}"></Column>
        <Column field="createdAt" header="作成日時" :style="{width:'200px'}"></Column>
        <Column field="description" header="詳細" :style="{width:'200px'}"></Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEvent from "@/models/matchEvent"
import { MatchEventStatus } from "@/models/matchEventStatus";
import { Unsubscribe } from "@firebase/util";

export default defineComponent({
    async created(){
        const placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
        if (this.$store.getters.hasAccess(this.$route.params.placeId)) {
            this.$store.commit("setCurrentPlaceById",{ placeId: placeId})
        } else {
            // リダイレクトするか、権限がない旨の表示
        }
        // this.matchEventList = await firebase.getMatchEventList(placeId)
        this.unsub = firebase.listenMatchEventList(placeId, this.matchEventList, (matchEvents)=>{
            this.matchEventList = matchEvents
        })
    },
    unmounted(){
        this.unsub()
    },
    data(){
        return {
            home: {icon: 'pi pi-home', to: '/dashboard/'},
            breadcrumbs: [
                {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
            ],
            matchEventList: [] as MatchEvent[],
            selectedMatchEvents: [] as MatchEvent[],
            unsub:{} as Unsubscribe
        }
    },
    methods:{
        async updateMatchStatus(status: MatchEventStatus){
            const placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
            await firebase.updateMatchStatus(placeId, this.selectedMatchEvents, status)
        }
    }
})
</script>

<style>

</style>