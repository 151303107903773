
import firebase from "@/firebase";
import { defineComponent } from 'vue'

export default defineComponent({
    methods:{
      callAllAddGeofirestoreFieldToPlaceOnCall: async function(){
        await firebase.callAllAddGeofireField()
      },
      callAllUpdateNearbyPlace: async function(){
        await firebase.callAllUpdateNearbyPlace()
      },
      callTestEmail: async function(){
        await firebase.testEmail()
      }
    }
})
