import {MatchType} from "@/models/matchType";
import {MatchEventStatus} from "@/models/matchEventStatus";
import MatchEventEntry from "@/models/matchEventEntry";
import { MatchEventEntryInfo } from "@/models/matchEventEntryInfo";
import { MatchApproveMethod } from "@/models/matchApproveMethod";


export default class MatchEvent {
  title: string;
  description: string;
  matchType: MatchType;
  startDateTime: Date;
  endDateTime: Date;
  createdAt: Date;
  countryCode: string;
  maxParticipants: number
  involvedUsers: MatchEventEntry[]
  status: MatchEventStatus
  startEntryDateTime: Date
  endEntryDateTime: Date
  requiredInfo: MatchEventEntryInfo[]
  approveMethod: MatchApproveMethod
  placeName: string
  placeId: string
  id?: string;
  constructor(
    title: string,
    description: string,
    matchType: MatchType,
    startDateTime: Date,
    endDateTime: Date,
    createdAt: Date,
    countryCode: string,
    maxParticipants: number,
    involvedUsers: MatchEventEntry[],
    status: MatchEventStatus,
    startEntryDateTime: Date,
    endEntryDateTime: Date,
    requiredInfo: MatchEventEntryInfo[],
    approveMethod: MatchApproveMethod,
    placeName: string,
    placeId: string,
    id?: string,
    ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.matchType = matchType;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.createdAt = createdAt;
    this.countryCode = countryCode
    this.maxParticipants = maxParticipants
    this.involvedUsers = involvedUsers
    this.startEntryDateTime = startEntryDateTime
    this.endEntryDateTime = endEntryDateTime
    this.status = status
    this.requiredInfo = requiredInfo
    this.approveMethod = approveMethod
    this.placeName = placeName
    this.placeId = placeId
  }
}
