<template>
    <div id="app">
        <Header/>
        <router-view/>
        <Footer/>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    Footer
  }
})

</script>

<style scoped>
body {
  margin: 0;
  background: #ebebeb;
  text-align: center;
}
/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #4b4b4b;

}
a {
  color: #4b4b4b;
  text-decoration: none;
} */
</style>