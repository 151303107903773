
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEvent from "@/models/matchEvent"
import { MatchEventStatus } from "@/models/matchEventStatus";
import { Unsubscribe } from "@firebase/util";

export default defineComponent({
    async created(){
        const placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
        if (this.$store.getters.hasAccess(this.$route.params.placeId)) {
            this.$store.commit("setCurrentPlaceById",{ placeId: placeId})
        } else {
            // リダイレクトするか、権限がない旨の表示
        }
        // this.matchEventList = await firebase.getMatchEventList(placeId)
        this.unsub = firebase.listenMatchEventList(placeId, this.matchEventList, (matchEvents)=>{
            this.matchEventList = matchEvents
        })
    },
    unmounted(){
        this.unsub()
    },
    data(){
        return {
            home: {icon: 'pi pi-home', to: '/dashboard/'},
            breadcrumbs: [
                {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
            ],
            matchEventList: [] as MatchEvent[],
            selectedMatchEvents: [] as MatchEvent[],
            unsub:{} as Unsubscribe
        }
    },
    methods:{
        async updateMatchStatus(status: MatchEventStatus){
            const placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
            await firebase.updateMatchStatus(placeId, this.selectedMatchEvents, status)
        }
    }
})
