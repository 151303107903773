<template>
  <div>
    <div v-for="(error, index) in errors" :key="index">
        <p>{{error}}</p>
    </div>
      <Card class="col-6 col-offset-3">
        <template #title>
        サインアップ
        </template>
        <template #content>
        <div>
        <span class="p-float-label mt-5 mb-5">
            <InputText name="email" type="text" v-model="email" class="p-inputtext-lg w-full"/>
            <label for="email">Emailアドレス</label>
          </span>
        <span class="p-float-label mb-5">
            <InputText name="password" type="password" v-model="password" class="p-inputtext-lg w-full"/>
            <label for="password">パスワード</label>
          </span>
        <span class="p-float-label mb-5">
            <InputText name="passwordConfirm" type="password" v-model="passwordConfirm" class="p-inputtext-lg w-full"/>
            <label for="passwordConfirm">パスワード（確認）</label>
          </span>
        <Button type="button" value="signin" @click="signupButtonClicked" class="mb-3">サインアップ</Button>
        <div>
        <router-link to="/signin">or サインイン</router-link>
        </div>
        </div>
        </template>
    </Card>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import firebase from '@/firebase'

export default defineComponent({
    // created(){
    //     this.$store.dispatch('onAuth')
    // },
    data() {
        return {
            email: "",
            password: "",
            passwordConfirm: "",
            errors: []
        }
    },
    methods:{
        async signupButtonClicked() {
            if (this.password == "" || this.email == "") {
                this.errors.push("空欄があります。")
                return
            }
            if (this.password !== this.passwordConfirm) {
                this.errors.push("パスワードが一致しません。")
                return
            }
            await firebase.signup(this.email, this.password)
            if (this.$route.query.redirect != undefined) {
                this.$router.push(this.$route.query.redirect)
            } else {
                this.$router.push("/dashboard")
            }
        }
    }
})
</script>

<style>

</style>