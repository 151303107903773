<template>
  <div>
    <Card class="col-6 col-offset-3">
        <template #title>
        サインイン
        </template>
        <template #content>
        <div>
        <span class="p-float-label mt-5 mb-5">
            <InputText name="email" type="text" v-model="email" class="p-inputtext-lg w-full"/>
            <label for="email">Emailアドレス</label>
          </span>
        <span class="p-float-label mb-5">
            <InputText name="password" type="password" v-model="password" class="p-inputtext-lg w-full"/>
            <label for="password">パスワード</label>
          </span>
        <Button type="button" value="signin" @click="signinButtonClicked" class="mb-3">サインイン</Button>
        <div>
        <router-link to="/signup">or サインアップ</router-link>
        </div>
        </div>
        </template>
    </Card>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import firebase from '@/firebase'

export default defineComponent({
    data() {
        return {
            email: "",
            password: "",
        }
    },
    methods:{
        async signinButtonClicked(){
            await firebase.signin(this.email, this.password)
            if (this.$route.query.redirect != undefined) {
                this.$router.push(this.$route.query.redirect)
            } else {
                this.$router.push("/dashboard")
            }
        }
    }
})
</script>

<style>

</style>