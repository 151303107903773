<template>
    <div id="news-container">
    <small>お知らせ</small>
    <h1>poolmatchのイラストができました。</h1>
    <h3>poolmatchのキービジュアルとなるイラストを紹介します。</h3>
    <p class="right">
        <small>2021年10月15日</small>
    </p>
    
    <p>
        日頃よりpoolmatchを使って頂きありがとうございます。
    </p>
    <p>
        この度、poolmatchをよりみなさんの身近に感じてもらうために、poolmatchオリジナルのキービジュアルとなるイラストを作成したので、そのご紹介をさせてください。
    </p>
    <div id="img-container">
        <img src="../assets/poolmatch_all.png" alt="">
        <p>
        poolmatchキービジュアル
        </p>
    </div>
    <p>
        
        こちらのイラストは、ビリヤードをモチーフとしたイラストを描かれているBALL in HAND（<a href="https://twitter.com/BALLinHAND_">@BALLinHAND_</a>）さんにお願いして描いて頂きました。
        「poolmatchが老若男女、様々な方の輪をつなげて、今まで以上にビリヤードを楽しむための一助になりたい」という思いが込められたキービジュアルになっています。
    </p>
    <p>
        今後アプリ内やサイトSNSアカウントなど各種クリエイティブ部分で登場させていく予定で、
        カラフルで、明るくカジュアルな雰囲気でpoolmatchをより盛り上げてくれるものと確信しています。
    </p>
    
    <p>
        poolmatchをこのイラストのような明るく楽しい雰囲気の場所にしていければと思っているので、引き続き宜しくお願いします！
    </p>
    <p class="right">
        poolmatch運営
    </p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    #news-container{
        max-width: 800px;
        margin: 0 auto;
        background-color: #fff;
        padding: 1rem;
        border-radius: 2rem;
    }
    .right{
        text-align: right;
    }
    img {
        width: 80%;
    }
    #img-container{
        padding: 2rem;
        margin: 0 auto;
        text-align: center;
    }
</style>