<template>
  <div
    id="app-sidebar-2"
    class="sticky bg-gray-800 surface-section h-screen hidden lg:block flex-shrink-0 absolute left-0 top-0 z-2 border-right-1 surface-border select-none"
  >
    <ul class="list-none p-3 m-0">
      <router-link :to="`/dashboard/${this.$route.params.placeId}/matches` ">
      <li
        class="m-0 flex align-items-center cursor-pointer p-3 hover:bg-gray-900 border-round text-gray-100 hover:text-gray-50 transition-duration-150 transition-colors"
      >
      <i class="pi pi-calendar mr-2"></i>
        イベント
      </li>
      </router-link>


      <router-link :to="`/dashboard/${this.$route.params.placeId}/profile` ">
      <li
        class="m-0 flex align-items-center cursor-pointer p-3 hover:bg-gray-900 border-round text-gray-100 hover:text-gray-50 transition-duration-150 transition-colors"
      >
      <i class="pi pi-cog mr-2"></i>
      設定
      </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
li {
  color: white;
  display: list-item;
}
</style>