<template>
    <div class="surface-0 p-2">
    <Breadcrumb :home="home" :model="breadcrumbs" />
        <h1>{{this.match.title}}</h1>
        <p>ステータス：{{this.match.status}}</p>
        <div>
            <Button label="公開する" class="m-1" @click="this.updateMatchStatus('public')" v-bind:disabled="isPublic()"/>
            <Button label="下書きに戻す" class="p-button-outlined m-1" @click="this.updateMatchStatus('draft')" v-bind:disabled="isDraft()"/>
            <Button label="中止する" class="p-button-outlined p-button-danger m-1" @click="this.updateMatchStatus('canceled')" v-bind:disabled="isCanceled()"/>
      </div>
        <TabMenu :model="tabItems" v-model:activeIndex="activeIndex" />
        <ul class="list-none p-0 m-0" v-if="this.activeIndex == 0">
            <li class="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap" v-for="[key, value] in Object.entries(match)" :key="key">
                <div class="text-500 w-6 md:w-2 font-medium">{{key}}</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{value}}</div>
                <div class="w-6 md:w-2 flex justify-content-end">
                </div>
            </li>
        </ul>
        <div v-if="this.activeIndex == 1">
            <Button class="m-1" @click="$router.push(`/dashboard/${this.$route.params.placeId}/matches/${this.$route.params.matchId}/addParticipants`)" label="参加者を追加する" v-if="selectedEntries.length == 0"/>
            <div v-if="selectedEntries.length != 0">
                <Button label="一括で承諾する" class="m-1" @click="this.approveSelectedEntries"/>
                <Button label="一括で拒否する" class="p-button-outlined p-button-danger m-1" @click="this.rejectSelectedEntries"/>
            </div>
            <DataTable :value="this.matchEventEntries" v-model:selection="selectedEntries" dataKey="id" :scrollable="true" scrollHeight="400px" scrollDirection="both">
                <Column selectionMode="multiple"></Column>
                <Column field="status" header="承認ステータス" :style="{width:'200px'}">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.status == 'approved'">
                            承認済み
                        </div>
                        <div v-else-if="slotProps.data.status == 'rejected'">
                            非承認
                        </div>
                        <div v-else>
                            未承認
                        </div>
                    </template>
                </Column>
                <Column field="name" header="名前" :style="{width:'200px'}"></Column>
                <Column field="email" header="Emailアドレス" :style="{width:'200px'}"></Column>
                <Column field="phone" header="電話番号" :style="{width:'200px'}"></Column>
                <Column field="class" header="クラス" :style="{width:'200px'}"></Column>
                <Column field="createdAt" header="作成日時" :style="{width:'200px'}"></Column>
            </DataTable>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEvent from "@/models/matchEvent";
import MatchEventEntry from "@/models/matchEventEntry";
import { MatchEventStatus } from "@/models/matchEventStatus";
export default defineComponent({
    async created(){
        this.placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
        this.matchId = typeof(this.$route.params.matchId) == 'string' ? this.$route.params.matchId : this.$route.params.matchId[0]
        const matchPromise =  firebase.getMatchInfo(this.placeId, this.matchId)
        const matchEventEntriesPromise = firebase.getMatchEventEntries(this.placeId, this.matchId)
        const promise = await Promise.all([matchPromise, matchEventEntriesPromise])
        this.match = promise[0]
        this.matchEventEntries = promise[1]
    },
    data(){
        return {
            placeId: "",
            matchId: "",
            match: {} as MatchEvent | undefined,
            matchEventEntries: [] as MatchEventEntry[],
            home: {icon: 'pi pi-home', to: '/dashboard/'},
            breadcrumbs: [
                {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
                {label: 'イベント詳細', to: "/dashboard/" + this.$route.params.placeId + "/matches/" + this.$route.params.matchId},
            ],
            tabItems:[
                {label: "試合情報"},
                {label: "参加者一覧"}
            ],
            activeIndex: 0,
            selectedEntries: [],
        }
    },
    methods:{
        isExcludedField(key: string){
            return ["countryCode",].includes(key)
        },
        async approveSelectedEntries(){
            await firebase.updateEntryStatus(this.placeId, this.matchId, this.selectedEntries, "approve")
        },
        async rejectSelectedEntries(){
            await firebase.updateEntryStatus(this.placeId, this.matchId, this.selectedEntries, "reject")
        },
        async updateMatchStatus(status: MatchEventStatus){
            const placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
            if (this.match != undefined) {
                await firebase.updateMatchStatus(placeId, [this.match], status)
            }
        },
        isPublic(){
            return this.match?.status == "public"
        },
        isDraft(){
            return this.match?.status == "draft"
        },
        isCanceled(){
            return this.match?.status == "canceled"
        }
    }
})
</script>

<style>

</style>