export default class Place {
    id?: string
    name: string | null
    description: string | null
    countryCode: string | null
    createdAt: Date | null
    lastUpdatedAt: Date | null
    address: string | null
    public: boolean | null
    staff: string[]
    searchTerms: string[]
    constructor(init: Partial<Place>) {
        this.id = init.id
        this.name = init.name ?? null
        this.description = init.description ?? null
        this.countryCode = init.countryCode ?? null
        this.createdAt = init.createdAt ?? null
        this.lastUpdatedAt = init.lastUpdatedAt ?? null
        this.address = init.address ?? null
        this.public = init.public ?? false
        this.staff = init.staff ?? []
        this.searchTerms = init.searchTerms ?? []
    }
    
}

