<template>
  <!-- <header>
    <router-link to="/"><img src="@/assets/poolmatch_logo_1.png" alt="" id="logo"></router-link>
    <router-link to="/"><h1>poolmatch</h1></router-link>
    
    <nav>
      <ul>
        <router-link to="/dashboard">
        <li>User ID{{this.$store.state.user.uid}}</li>
        </router-link>
        <li v-if="this.$store.state.isSignedin">
          <Button class="p-button-outlined m-1" @click.stop.prevent="signoutButtonClicked">sign out</Button>
        </li>
      </ul>
    </nav>   
    </header> -->
    <div>
        <Menubar :model="items">
            <template #start>
              <div id="logo-container">
                <router-link to="/"><img src="@/assets/poolmatch_logo.png" alt="" id="logo" class="mr-2"></router-link>
                <router-link to="/"><h1>poolmatch</h1></router-link>
              </div>
            </template>
            <template #end>
                <div class="flex">
                  <div class="justify-content-center">
                    <p>
                      <i class="pi pi-user mr-2"></i>
                      User ID：{{this.$store.state.user.uid}}
                    </p>
                  </div>
                <Button icon="pi pi-angle-down" class="p-button-text" @click="toggle" aria-controls="overlay_menu"></Button>
                <Menu id="overlay_menu" ref="menu" :model="menuButtonItem" :popup="true" />
                </div>
            </template>
        </Menubar>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import firebase from '@/firebase'

export default defineComponent({
  methods:{
    async signoutButtonClicked(){
      await firebase.signout()
    },
    toggle(event) {
    this.$refs.menu.toggle(event);
    }
  },
  data(){
    return{
      menuButtonItem: [
        {
          label: 'ログアウト',
          command: async () => {
            await this.signoutButtonClicked()
            await this.$router.push("/signin")
          }
        }
      ],
      items:[]
    }
  }
})
</script>

<style scoped>
    header{ height: 80px; display: flex; width: 100%; align-items: center; background-color: #ebebeb;}
    #logo-container{height: 60px; display: flex; align-items: center;}
    h1 { margin-right: auto;}
    ul{ display: flex; list-style: none; margin-right: 10px;}
    li{padding: 10px 30px}
    #logo{ height: 50px; object-fit: contain;}
</style>