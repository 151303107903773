
import { defineComponent } from "vue";
import firebase from "@/firebase";
import MatchEvent from "@/models/matchEvent";
import MatchEventEntry from "@/models/matchEventEntry";
import { MatchEventStatus } from "@/models/matchEventStatus";
export default defineComponent({
    async created(){
        this.placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
        this.matchId = typeof(this.$route.params.matchId) == 'string' ? this.$route.params.matchId : this.$route.params.matchId[0]
        const matchPromise =  firebase.getMatchInfo(this.placeId, this.matchId)
        const matchEventEntriesPromise = firebase.getMatchEventEntries(this.placeId, this.matchId)
        const promise = await Promise.all([matchPromise, matchEventEntriesPromise])
        this.match = promise[0]
        this.matchEventEntries = promise[1]
    },
    data(){
        return {
            placeId: "",
            matchId: "",
            match: {} as MatchEvent | undefined,
            matchEventEntries: [] as MatchEventEntry[],
            home: {icon: 'pi pi-home', to: '/dashboard/'},
            breadcrumbs: [
                {label: 'イベント一覧', to: "/dashboard/" + this.$route.params.placeId + "/matches"},
                {label: 'イベント詳細', to: "/dashboard/" + this.$route.params.placeId + "/matches/" + this.$route.params.matchId},
            ],
            tabItems:[
                {label: "試合情報"},
                {label: "参加者一覧"}
            ],
            activeIndex: 0,
            selectedEntries: [],
        }
    },
    methods:{
        isExcludedField(key: string){
            return ["countryCode",].includes(key)
        },
        async approveSelectedEntries(){
            await firebase.updateEntryStatus(this.placeId, this.matchId, this.selectedEntries, "approve")
        },
        async rejectSelectedEntries(){
            await firebase.updateEntryStatus(this.placeId, this.matchId, this.selectedEntries, "reject")
        },
        async updateMatchStatus(status: MatchEventStatus){
            const placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
            if (this.match != undefined) {
                await firebase.updateMatchStatus(placeId, [this.match], status)
            }
        },
        isPublic(){
            return this.match?.status == "public"
        },
        isDraft(){
            return this.match?.status == "draft"
        },
        isCanceled(){
            return this.match?.status == "canceled"
        }
    }
})
