
import { defineComponent } from "vue";
import Place from "@/models/place";
import firebase from "@/firebase";
import { Unsubscribe } from "@firebase/util";

export default defineComponent({
    async created(){
        this.placeId = typeof(this.$route.params.placeId) == 'string' ? this.$route.params.placeId : this.$route.params.placeId[0]
        this.unsub = firebase.listenPlaceInfo(this.placeId, (place)=>{
            this.place = place
        })
    },
    unmounted(){
        this.unsub()
    },
    data(){
        return {
            placeId: "",
            place: {} as Place | undefined,
            home: {icon: 'pi pi-home', to: '/dashboard/'},
            breadcrumbs: [
                {label: '店舗詳細', to: "/dashboard/" + this.$route.params.placeId + "/profile"},
            ],
            editingItemKey: "" as string,
            editingItemValue: true as string | boolean,
            publicToggle:[
                {name: "公開する", value: true},
                {name: "非公開にする", value: false}
            ],
            searchTerms: [] as string[],
            activeIndex: 0,
            unsub: {} as Unsubscribe
        }
    },
    methods:{
        setEditingItem(key: string, value: string | boolean){
            if (this.isEditable(key)) {
                this.editingItemValue = value
                this.editingItemKey = key
            }
        },
        unsetEditingItem(key: string) {
            if (this.editingItemKey == key) {
                this.editingItemKey = ""
                this.editingItemValue = ""
            }
        },
        isEditable: function(key: string){
            return ["name", "description", "address", "public"].includes(key)
        },
        isDescription: function(key: string){
            return key == "description"
        },
        isPublic: function(key: string){
            return key == "public"
        },
        updateField: async function(){
            await firebase.updatePlaceField(this.placeId, {[this.editingItemKey] : this.editingItemValue})
            this.unsetEditingItem(this.editingItemKey)
        },
        updatePlaceSearchTerms: async function(){
            console.log(this.searchTerms)
            await firebase.updatePlaceSearchTerm(this.placeId, this.searchTerms)
            this.searchTerms = []
        },
        removePlaceSearchTerm: async function(term: string){
            const terms = [term]
            await firebase.removePlaceSearchTerm(this.placeId, terms)
        },
        updateSearchTermSetting: async function(){
            await firebase.callRefreshSearchTerm([this.placeId])
        },
        callGeofireFunction: async function(){
            await firebase.callAddGeofireField([this.placeId])
        },
        callUpdateNearbyPlace: async function(){
            await firebase.callUpdateNearbyPlace([this.placeId])
        }
    }
})
