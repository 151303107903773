<template>
  <div id="about">
      <div>
          <img src="../assets/poolmatch_all.png" alt="" id="logo">
      </div>
      <p>"poolmatch" is the app you can find someone you play billiard with.</p>
      <a href="https://apps.apple.com/jp/app/poolmatch/id1560150091#?platform=iphone"><img src="../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="" class="store_badge"></a>
      <a href="https://play.google.com/store/apps/details?id=com.teamO.poolmatch"><img src="../assets/googleplay_badge.png" alt="" class="store_badge"></a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#logo {
    width: 200px;
}
.store_badge{
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
}
</style>