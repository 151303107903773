<template>
  <div class="dashboard">
    <Header/>
    <div id="d-main" class="min-h-screen flex relative lg:static surface-ground">
      <SideMenu style="width: 240px" v-if="this.$route.params.placeId != undefined"/>
      <router-view v-bind:style="mainPaneWidth"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Header from "@/components/dashboard/Header.vue"
import SideMenu from "@/components/dashboard/SideMenu.vue"

export default defineComponent({
  components: {
    Header,
    SideMenu
  },
  computed:{
    mainPaneWidth: function(){
      if (this.$route.params.placeId != undefined) {
        return "width: calc(100% - 240px)"
      } else {
        return "width: 100%"
      }
    }
  }
})
</script>

<style scoped>

</style>